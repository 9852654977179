import PropTypes from 'prop-types';
import React from 'react';

const Heading1 = ({ text = '', customClass, centerText, children, style, onClick }) => (
    <h1 className={`heading-one typography-text ${customClass} ${centerText ? 'center-text' : ''}`} style={style} onClick={onClick}>
        {text || children}
    </h1>
);

Heading1.propTypes = {
    text: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    centerText: PropTypes.bool,
    children: PropTypes.element,
    style: PropTypes.object
};

export default Heading1;
