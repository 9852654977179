/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';
import React from 'react';

import LWPButton from '../Button/LWPButton';
import Heading1 from '../Typography/Heading1';
import Paragraph from '../Typography/Paragraph';

const CommonStatus = ({
    heading,
    subHeading,
    headingClass,
    subHeadingClass,
    buttonText,
    loading,
    onClick = () => {},
    image = '',
    image1 = '',
    image2 = '',
    imageAlt,
    className = '',
    children
}) => {
    return (
        <div className={`common_status_container ${className}`}>
            <div className={`common_status_img`}>
                {image && <img src={image} alt={imageAlt} className="common_status_container_image" />}
                {image1 && <img src={image1} alt={imageAlt} className="common_status_container_image" />}
            </div>
            {heading && <Heading1 text={heading} centerText customClass={headingClass} />}
            {subHeading && <Paragraph text={subHeading} centerText customClass={subHeadingClass} />}
            {image2 && (
                <div className={`common_status_img`}>
                    {image2 && <img src={image2} alt={imageAlt} className="common_status_container_image" />}
                </div>
            )}
            {buttonText && <LWPButton onClick={onClick} loading={loading} btnText={buttonText} size="large" />}
            {children}
        </div>
    );
};

CommonStatus.propTypes = {
    children: PropTypes.element,
    heading: PropTypes.string,
    loading: PropTypes.bool,
    subHeading: PropTypes.string,
    headingClass: PropTypes.string,
    subHeadingClass: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    image: PropTypes.string,
    image1: PropTypes.string,
    image2: PropTypes.string,
    imageAlt: PropTypes.string,
    className: PropTypes.string
};

export default CommonStatus;
